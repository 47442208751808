import React from 'react';
import { Image } from '@alterpage/gatsby-plugin-image';

import {
    resellHistory,
    noResellsImage,
    title as titleClass,
    noResellsDescription as noResellsDescriptionClass,
    noResellsTitle as noResellsTitleClass,
    button as buttonClass,
    table,
    list,
    wrapper,
    errorText,
} from './resale-history.module.scss';
import { ISection } from '../../models/section.model';
import { INavLink } from '../../models/nav-link.model';
import { useResale } from '../../hooks/use-resale';
import useTranslations from '../../hooks/use-translations';

import UserLayout from '../../layouts/user-layout';
import Title from '../atoms/title';
import HistoryTable from '../molecules/history-table';
import Button from '../atoms/button';
import HistoryList from '../molecules/history-list';
import Markdown from '../hoc/markdown';
import Loader from '../atoms/loader';

export interface IResellHistorySection extends ISection {
    items: {
        links: INavLink[];
    };
}

export interface IResaleHistoryProps {
    className?: string;
    section: IResellHistorySection;
    TitleTag?: React.ElementType;
}

const ResaleHistory = ({ className = '', section, TitleTag }: IResaleHistoryProps) => {
    const t = useTranslations('ResaleHistory');
    const { sectionId, css, style } = section;
    const [subtitle, noResellsTitle, noResellsDescription] = section.content.texts;
    const button = section.content.buttons[0];
    const noResellsImageMedia = section.content.media;

    const { links } = section.items;

    const resale = useResale({ queries: ['list'] });
    const resales = resale.list.data;

    return (
        <UserLayout
            sectionId={sectionId}
            className={[resellHistory, className].join(' ')}
            links={links}
            style={style}
            css={css}
        >
            {resale.list.isLoading && <Loader width={'cover'} />}
            {resale.list.isSuccess && resales && resales.length > 0 && (
                <>
                    <Title Tag={TitleTag} className={titleClass}>
                        {subtitle}
                    </Title>
                    {button?.url && (
                        <Button className={buttonClass} as="link" to={button.url}>
                            {button.text}
                        </Button>
                    )}
                    <HistoryTable className={table} items={resales} />
                    <HistoryList className={list} items={resales} />
                </>
            )}
            {resale.list.isSuccess && resales?.length === 0 && (
                <div className={wrapper}>
                    <Image className={noResellsImage} media={noResellsImageMedia} />
                    <Markdown className={noResellsTitleClass}>{noResellsTitle || ''}</Markdown>
                    <Markdown className={noResellsDescriptionClass}>
                        {noResellsDescription || ''}
                    </Markdown>
                    {button?.url && (
                        <Button as="link" to={button.url}>
                            {button.text}
                        </Button>
                    )}
                </div>
            )}
            {resale.list.isError && <p className={errorText}>{t.fetchingError}</p>}
        </UserLayout>
    );
};

export default ResaleHistory;
