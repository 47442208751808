// extracted by mini-css-extract-plugin
export var button = "resale-history-module--button--f7b1f";
export var errorText = "resale-history-module--error-text--b7feb";
export var list = "resale-history-module--list--40448";
export var noResellsDescription = "resale-history-module--no-resells-description--bc76a";
export var noResellsImage = "resale-history-module--no-resells-image--9cb70";
export var noResellsTitle = "resale-history-module--no-resells-title--94527";
export var resellHistory = "resale-history-module--resell-history--6f2b8";
export var table = "resale-history-module--table--2b0cf";
export var title = "resale-history-module--title--979af";
export var wrapper = "resale-history-module--wrapper--3c4b6";