import { useMemo } from 'react';

import { useDispatch, useSelector } from '../redux/hooks';
import {
    setResaleStepMode,
    setResaleMutation,
    selectResaleState,
    setResaleStepValues,
    resetResaleStep,
    resetResale,
} from '../redux/slices/resale.slice';
import {
    useCreateResaleMutation,
    useGetResaleItemsQuery,
    useGetResalePaymentMethodProvidersQuery,
    useGetResaleQuery,
    useGetResaleShipmentMethodProvidersQuery,
    useGetResalesQuery,
    useValidateResaleMutation,
} from '../redux/api/resale';
import { getRtkQueryErrors } from '../utils/get-rtk-query-errors';
import useTranslations from './use-translations';

export type TUseResaleQueries = 'single' | 'list' | 'shipment' | 'payment' | 'items';

interface IUseResaleConfig {
    queries?: TUseResaleQueries[];
    fixedKeys?: { create?: string; validate?: string };
}

export const useResale = ({ queries = [], fixedKeys }: IUseResaleConfig | undefined = {}) => {
    const dispatch = useDispatch();
    const t = useTranslations('RTKQueryApi');
    const resaleState = useSelector(selectResaleState);
    const mutation = resaleState.mutation;

    const search = typeof window !== 'undefined' ? window.location.search : '';
    const urlParams = new URLSearchParams(search);
    const resaleUid = urlParams.get('resale');

    const skipListQuery = !queries.includes('list');
    const skipSingleQuery = !queries.includes('single') || !resaleUid;
    const skipItemsQuery = !queries.includes('items');
    const skipShipmentQuery = !queries.includes('shipment');
    const skipPaymentQuery = !queries.includes('payment');

    const getResalesQuery = useGetResalesQuery(undefined, { skip: skipListQuery });
    const getResaleQuery = useGetResaleQuery(resaleUid, { skip: skipSingleQuery });
    const getResaleItemsQuery = useGetResaleItemsQuery(undefined, {
        skip: skipItemsQuery,
        refetchOnFocus: false,
    });
    const getShipmentQuery = useGetResaleShipmentMethodProvidersQuery(mutation, {
        skip: skipShipmentQuery,
    });
    const getPaymentQuery = useGetResalePaymentMethodProvidersQuery(mutation, {
        skip: skipPaymentQuery,
    });

    const createConfig = fixedKeys?.create ? { fixedCacheKey: fixedKeys.create } : {};
    const validateConfig = fixedKeys?.validate ? { fixedCacheKey: fixedKeys.validate } : {};

    const [create, createResaleMutation] = useCreateResaleMutation(createConfig);
    const [validate, validateResaleMutation] = useValidateResaleMutation(validateConfig);

    const listErrors = useMemo(() => {
        return getRtkQueryErrors(getResalesQuery.error, t.errors);
    }, [getResalesQuery.error, t.errors]);
    const singleErrors = useMemo(() => {
        return getRtkQueryErrors(getResaleQuery.error, t.errors);
    }, [getResaleQuery.error, t.errors]);
    const itemsErrors = useMemo(() => {
        return getRtkQueryErrors(getResaleItemsQuery.error, t.errors);
    }, [getResaleItemsQuery.error, t.errors]);
    const shipmentErrors = useMemo(() => {
        return getRtkQueryErrors(getShipmentQuery.error, t.errors);
    }, [getShipmentQuery.error, t.errors]);
    const paymentErrors = useMemo(() => {
        return getRtkQueryErrors(getPaymentQuery.error, t.errors);
    }, [getPaymentQuery.error, t.errors]);
    const createErrors = useMemo(() => {
        return getRtkQueryErrors(createResaleMutation.error, t.errors);
    }, [createResaleMutation.error, t.errors]);
    const validateErrors = useMemo(() => {
        return getRtkQueryErrors(validateResaleMutation.error, t.errors);
    }, [t.errors, validateResaleMutation.error]);

    return {
        new: resaleState,
        isComplete: Object.values(resaleState.steps).every((step) => step.isComplete),
        single: {
            data: getResaleQuery.data,
            isFetching: getResaleQuery.isFetching,
            isLoading: getResaleQuery.isLoading,
            isSuccess: getResaleQuery.isSuccess,
            isError: getResaleQuery.isError,
            errors: singleErrors,
        },
        list: {
            data: getResalesQuery.data,
            isFetching: getResalesQuery.isFetching,
            isLoading: getResalesQuery.isLoading,
            isSuccess: getResalesQuery.isSuccess,
            isError: getResalesQuery.isError,
            errors: listErrors,
        },
        items: {
            data: getResaleItemsQuery.data,
            isFetching: getResaleItemsQuery.isFetching,
            isLoading: getResaleItemsQuery.isLoading,
            isSuccess: getResaleItemsQuery.isSuccess,
            isError: getResaleItemsQuery.isError,
            errors: itemsErrors,
        },
        shipment: {
            data: getShipmentQuery.data,
            isFetching: getShipmentQuery.isFetching,
            isLoading: getShipmentQuery.isLoading,
            isSuccess: getShipmentQuery.isSuccess,
            isError: getShipmentQuery.isError,
            errors: shipmentErrors,
        },
        payment: {
            data: getPaymentQuery.data,
            isFetching: getPaymentQuery.isFetching,
            isLoading: getPaymentQuery.isLoading,
            isSuccess: getPaymentQuery.isSuccess,
            isError: getPaymentQuery.isError,
            errors: paymentErrors,
        },
        create: {
            fetch: create,
            data: createResaleMutation.data,
            isLoading: createResaleMutation.isLoading,
            isSuccess: createResaleMutation.isSuccess,
            isError: createResaleMutation.isError,
            errors: createErrors,
        },
        validate: {
            fetch: validate,
            data: validateResaleMutation.data,
            isLoading: validateResaleMutation.isLoading,
            isSuccess: validateResaleMutation.isSuccess,
            isError: validateResaleMutation.isError,
            errors: validateErrors,
        },
        setStepMode: (arg: Parameters<typeof setResaleStepMode>[0]) => {
            dispatch(setResaleStepMode(arg));
        },
        setStepValues: (arg: Parameters<typeof setResaleStepValues>[0]) => {
            dispatch(setResaleStepValues(arg));
        },
        setMutation: (arg: Parameters<typeof setResaleMutation>[0]) => {
            dispatch(setResaleMutation(arg));
        },
        resetStep: (arg: Parameters<typeof resetResaleStep>[0]) => {
            dispatch(resetResaleStep(arg));
        },
        reset: () => {
            dispatch(resetResale());
        },
    };
};
