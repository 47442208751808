import alterpressApi from './base';

import {
    ICreateResaleMutation,
    IResale,
    IResaleItem,
    IResalePaymentMethodProvider,
    IResaleShipmentMethodProvider,
    IValidateResaleMutation,
} from '../../models/resale.model';

export const resaleApi = alterpressApi
    .enhanceEndpoints({
        addTagTypes: ['Resale', 'ResaleItems'],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getResales: builder.query<IResale[], void>({
                query: () => 'resales?expand=items,orders.media,status',
                providesTags: ['Resale'],
            }),
            getResale: builder.query<IResale, string | undefined | null>({
                query: (uid) => `resales/${uid}?expand=items,orders,orders.media,status`,
                providesTags: ['Resale'],
            }),
            getResaleItems: builder.query<IResaleItem[], void>({
                query: () => `resales/items`,
                providesTags: ['ResaleItems'],
            }),
            createResale: builder.mutation<IResale, ICreateResaleMutation>({
                query: (data) => ({
                    url: `resales`,
                    method: 'POST',
                    body: data,
                }),
                invalidatesTags: ['Resale', 'ResaleItems'],
            }),
            validateResale: builder.mutation<void, IValidateResaleMutation>({
                query: (data) => ({
                    url: `resales/validate`,
                    method: 'POST',
                    body: data,
                }),
            }),
            getResaleShipmentMethodProviders: builder.query<
                IResaleShipmentMethodProvider[],
                IValidateResaleMutation
            >({
                query: (data) => ({
                    url: `resales/available-shipments`,
                    method: 'POST',
                    body: data,
                }),
            }),
            getResalePaymentMethodProviders: builder.query<
                IResalePaymentMethodProvider[],
                IValidateResaleMutation
            >({
                query: (data) => ({
                    url: `resales/available-payments`,
                    method: 'POST',
                    body: data,
                }),
            }),
        }),
    });

export const {
    useGetResalesQuery,
    useGetResaleQuery,
    useValidateResaleMutation,
    useGetResaleItemsQuery,
    useCreateResaleMutation,
    useGetResalePaymentMethodProvidersQuery,
    useGetResaleShipmentMethodProvidersQuery,
} = resaleApi;
